<script setup lang="ts">
import { useRoute } from 'nuxt/app';

defineProps({
  categories_in_sidebar: {
    type: Object,
    required: true,
  },
  filtersStore: {
    type: Object,
  }
})
const route = useRoute()

const currentPath = route.fullPath
</script>

<template>
  <ul class="p-3 bg-white rounded-xl border">
    <li class="mb-1 ">
      <NuxtLink :to="localePath({ name: 'home' })" class="text-gray-600">Real Estate</NuxtLink>
      <ul class="ps-3">
        <li class="text-gray-600 text-sm "><NuxtLink :to="localePath(`/${categories_in_sidebar.slug}/${route.params.slug.slice(1).join('/')}`)">{{ categories_in_sidebar.name }} <span class="text-indigo-900">({{ categories_in_sidebar.total }})</span></NuxtLink></li>
        <ul class="list-disc ps-5">
          <template v-for="(category, id) in categories_in_sidebar.children" :key="id">
            <li class="text-gray-600 text-sm" v-if="category.total">
              <NuxtLink :to="localePath(`/${category.slug}/${route.params.slug.slice(1).join('/')}`)">
                {{ category.name }} <span class="text-indigo-900">({{ category.total }})</span>
              </NuxtLink>
            </li>
          </template>

        </ul>
      </ul>
    </li>
  </ul>
</template>